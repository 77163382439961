import { createTheme, Theme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { TypographyOptions } from '@material-ui/core/styles/createTypography'
import { ComponentsProps } from '@material-ui/core/styles/props'
import { IWebsiteFont, IWebsiteMode } from '../models/Website'

export const MUIbreakpoints = createBreakpoints({})

export enum Fonts {
    Nunito = 'Nunito, sans-serif',
}

//TODO @IRINA remove this colors, we will not use it any more
export enum Colors {
    DarkText = '#0B1F40',
    GreyText = '#C1C1C1',
    GreyLink = '#C3CFE7',
    Background = '#F3F8FF',
    Primary = '#36D48F',
    PrimaryDark = '#23A46B',
    PrimaryLight = '#68deab',
    Secondary = '#3682FF',
    SecondaryDark = '#0057E7',
    SecondaryLight = '#68a1ff',

    // clubee Buttons
    //MainGreen = Primary
    GreenHoverCTA = '#4AD89B',
    GreenHover = '#00DC96',
    GreenActiveCTA = '#30BE81',
    GreenActive = '#00C27B',

    MainGrey = '#acbcdb',
    GreyHover = '#B1C2E1',
    GreyHoverIcon = '#ADC2E4',
    GreyActive = '#97A9C8',
    GreyActiveIcon = '#93A9CB',

    MainRed = '#eb5a5a',
    MainRedIcon = '#FE4C54',
    RedHover = '#EF7B7B',
    RedHoverIcon = '#FF7377',
    RedActive = '#D35151',
    RedActiveIcon = '#E4454B',

    MainYellow = '#f4ce5a',
    MainYellowIcon = '#FBCE3C',
    YellowHover = '#F5D26A',
    YellowHoverIcon = '#FBD254',
    YellowActive = '#DBB851',
    YellowActiveIcon = '#E1B837',

    //MainBlue = Secondary
    BlueHoverCTA = '#4A8FFF',
    BlueHover = '#328EFF',
    BlueHoverIcon = '#008DFF',
    BlueActiveCTA = '#3075E5',
    BlueActive = '#0F74ED',
    BlueActiveIcon = '#0072F5',

    MainDisabled = '#d5dded',
    DisabledHover = '#D9E0EE',
    DisabledHoverIcon = '#D8E0EF',
    DisabledActive = '#BFC6D5',
    DisabledActiveIcon = '#BEC6D7',

    OutlineActiveCTA = '#9aa9c5',
    OutlineHoverCTA = '#b4c2de',

    Facebook = '#3b5998',
    Twitter = '#1da1f2',

    Error = '#EB5A5A',

    GrayBackground = '#F5F9FF',
    FlfDividerColor = '#F5F5F5',
    FlfRedColor = '#ED1C24'
}

export function setTypographyTheme(font: IWebsiteFont): TypographyOptions {
    return {
        h1: {
            fontWeight: 700,
            fontSize: '54px',
            lineHeight: '64px',
            fontFamily: font?.name,
            [theme.breakpoints.down('sm')]: {
                fontSize: '36px',
                lineHeight: '43px',
            }
        },
        h2: {
            fontWeight: 700,
            fontSize: '36px',
            lineHeight: '50px',
            fontFamily: font?.name,
            [theme.breakpoints.down('sm')]: {
                fontSize: '28px',
                lineHeight: '39px',
            },
        },
        h3: {
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '38px',
            fontFamily: font?.name,
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                lineHeight: '26px',
            },
        },
        h4: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '25px',
            fontFamily: font?.name,
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '22px',
            },
        },
        body1: {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '25px',
            fontFamily: font?.name2,
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '22px',
            },
        },
        body2: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '22px',
            fontFamily: font?.name2,
            [theme.breakpoints.down('sm')]: {
                fontSize: '11px',
                lineHeight: '13px',
            },
        }
    }
}

export function overridesMuiButton(mode: IWebsiteMode): any {
    return {
        containedPrimary: {
            backgroundColor: mode.accent,
            color: mode.text_accent,
            border: 0,
            '&:hover' : {
                backgroundColor: mode.accent,
                color: mode.text_accent,
                border: 0,
            },
            '&.Mui-disabled': {
                backgroundColor: mode.accent,
                color: mode.text_accent,
                border: 0,
                opacity: 0.4,
            }
        },
        outlinedSecondary: {
            background: 'none',
            border: `1px solid ${mode.text_background}`,
            color: mode.text_background,
            '&:hover' : {
                background: 'none',
                border: `1px solid ${mode.text_background}`,
                color: mode.text_background,
            },
            '&.Mui-disabled': {
                background: 'none',
                color: mode.text_background,
                border: `1px solid ${mode.text_background}`,
                opacity: 0.4,
            }
        },
        outlinedPrimary: {
            background: 'none',
            border: `1px solid ${mode.text_accent}`,
            color: mode.text_accent,
            '&:hover' : {
                background: 'none',
                border: `1px solid ${mode.text_accent}`,
                color: mode.text_accent,
            },
            '&.Mui-disabled': {
                background: 'none',
                border: `1px solid ${mode.text_accent}`,
                color: mode.text_accent,
                opacity: 0.4,
            }
        },
        textPrimary: {
            color: mode.text_background,
            background: 'none',
            backgroundColor: 'unset',
            '&:hover' : {
                background: 'none',
                color: mode.text_background,
                backgroundColor: 'unset'
            },
            '&.Mui-disabled': {
                background: 'none',
                color: mode.text_background,
                backgroundColor: 'unset',
                opacity: 0.4,
            }
        },
        text: {
            color: mode.text_background,
            background: 'none',
            '&:hover' : {
                background: 'none',
                color: mode.text_background,
            },
            '&.Mui-disabled': {
                background: 'none',
                color: mode.text_background,
                opacity: 0.4,
            }
        }
    }
}

export function overridesMuiTab(mode: IWebsiteMode): any {
    return {
        indicator: {
            backgroundColor: mode.text_background
        }
    }
}



interface IThemeProps extends ComponentsProps {
    sectionMode?: IWebsiteMode;
    colorFilters?: { [key: string]: string };
}

export interface ITheme extends Theme {
    props: IThemeProps
}

// Create a theme instance.
const theme  = createTheme({
    //NOTE error in console
    //Material-UI: The value provided (10) overflows.
    //The supported values are: [0,4,8,12,16,24,32,48].
    //10 > 7, you need to add the missing values. - Temporary added 48 three times more at the end
    spacing: [0, 4, 8, 12, 16, 24, 32, 48, 72, 96, 144],
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
}) as ITheme

theme.overrides = {
    MuiCssBaseline: {
        '@global': {
            html: {
                fontSize: '100%',
                height: '100%',
            },
            body: {
                height: '100%',
            },
            '#__next': {
                height: '100%',
            }
        }
    },
    MuiButton: {
        root: {
            textTransform: 'none',
        },
        sizeSmall: {
            padding: theme.spacing(1, 4),
            minHeight: '34px',
            minWidth: '102px',
            fontSize: '1rem'
        },
        sizeLarge: {
            padding: theme.spacing(1, 4),
            minHeight: '50px',
            minWidth: '102px',
            fontSize: '1rem'
        },
    },
    MuiPaper: {
        elevation1: {
            boxShadow: 'none',
        }
    },
    MuiSvgIcon: {
        root: {
            fontSize: '100%'
        }
    }
}


export default theme
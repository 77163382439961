import CssBaseline from '@material-ui/core/CssBaseline'
import { StylesProvider, MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import React from 'react'

import theme, { overridesMuiButton, overridesMuiTab, setTypographyTheme } from '@/shared/styles/MuiTheme'
import { useClub } from 'contexts/club'
import { WebsiteColorMode, WebsiteButtonStyle, IWebsiteMode } from '@/shared/models/Website'
import { unique } from '@/shared/utils/unique'
import hexToRgb from '@/shared/utils/hexToRgb'
import { Color, Solver } from '@/shared/utils/rgbToCssFilter'


export const mockModeColors: IWebsiteMode = {
	type: WebsiteColorMode.M1_MODE,
    background: '#FFFFFF', // white
	// eslint-disable-next-line @typescript-eslint/naming-convention
    text_background: '#000000', // black
    card: '#808080', // grey
    accent: '#FFA500', // orange
	// eslint-disable-next-line @typescript-eslint/naming-convention
    text_accent: '#000000', // black
}

interface IProps {
	children?: React.ReactNode;
}

const CustomThemeProvider = ({ children }: IProps): JSX.Element => {
    const { modes, font, website } = useClub()
	const defaultMode = modes?.find(mode => mode.type === (website?.default_color_mode ?? WebsiteColorMode.M1_MODE)) ?? mockModeColors
	let uniqueColors = [];

	(modes || []).forEach(mode => {
		const colors = Object.values(mode).filter(value => value && !Object.values(WebsiteColorMode).includes(value))
		uniqueColors = [...uniqueColors, ...colors]
	})
	uniqueColors = unique(uniqueColors)
	const colorFilters = {}
	uniqueColors.forEach(color => {
		const rgb = hexToRgb(color)
		const testColor = new Color(rgb[0], rgb[1], rgb[2])
		const solver = new Solver(testColor)
		const { filter = '' } = solver.solve()

		colorFilters[color] = filter
	})

	const mainTheme = createTheme(theme, { //NOTE deep merge theme with new props
		props: {
			sectionMode: defaultMode,
			colorFilters,
		},
		typography: {
			fontFamily: font?.name2,
			...setTypographyTheme(font)
		},
		overrides: {
			MuiCssBaseline: {
				'@global': {
					body: {
						fontFamily: font?.name2,
						color: defaultMode.text_background,
						backgroundColor: defaultMode.background,
					},
					a: {
						color: defaultMode.text_background,
					}
				},
			},
			MuiButton: {
				root: {
					borderRadius: website?.button_style ? 
						website?.button_style === WebsiteButtonStyle.ROUNDED ? '8px' :
							website?.button_style === WebsiteButtonStyle.PILL_SHAPED ? '50px' : 0
								: 0,
					fontFamily: font?.name2,
					
				},
				...overridesMuiButton(defaultMode)
			},
			MuiTab: {
				wrapper: {
					textTransform: 'none',
				},
				textColorInherit: {
					textTransform: 'none',
					opacity: 1,
					minWidth: 'auto',
				},
			},
			MuiTabs: {
				...overridesMuiTab(defaultMode)
			}
		},
		palette: {
			primary: {
				light: defaultMode.accent,
				main: defaultMode.accent,
				dark: defaultMode.accent,
			},
			secondary: {
				light: defaultMode.text_background,
				main: defaultMode.text_background,
				dark: defaultMode.text_background,
			}
		},
	})

	return (
		<StylesProvider injectFirst>
			<MuiThemeProvider theme={mainTheme}>
				<>
					{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
					<CssBaseline />
					{children}
				</>
			</MuiThemeProvider>
		</StylesProvider>
	)
}

export default CustomThemeProvider
